import React from 'react';
import { createProvider } from '@gluestack-ui/provider';
import { StyledProvider } from '@gluestack-style/react';
import { OverlayProvider } from '@gluestack-ui/overlay';
import { ToastProvider } from '@gluestack-ui/toast';
const GluestackUIStyledProvider = createProvider({ StyledProvider });
const GluestackUIProvider = ({ children, ...props }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(GluestackUIStyledProvider, { ...props },
            React.createElement(OverlayProvider, null,
                React.createElement(ToastProvider, null, children)))));
};
export { GluestackUIProvider, GluestackUIStyledProvider };
